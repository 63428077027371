import { InfoIcon } from 'lucide-react';
import { useSearchParams } from 'next/navigation';
import { toast } from 'sonner';

import { useToggleActiveStatus } from '@/app/(protected)/users/hooks/toggle-user-active-status';
import { Badge } from '@/components/badge';
import { Button } from '@/components/button';
import { DialogType, openDialog } from '@/components/dialog';
import { Highlight } from '@/components/highlight';
import { Panel } from '@/components/panel';
import { Tooltip } from '@/components/tooltip';
import { saveUsername, useInitImpersonation } from '@/forms/impersonation-form';
import { saveUserId } from '@/forms/kyb-kyc-form';
import { dayjs } from '@/libs/dayjs';
import { CheckStatus, EntityStatus } from '@/libs/enum';
import { type User } from '@/models/user';

const kybCheckStatusMap = {
  [CheckStatus.FAIL]: <Badge className="border-pink bg-pink/10 text-pink">KYB: Fail</Badge>,
  [CheckStatus.PASS]: <Badge className="border-green bg-green/10 text-green">KYB: Pass</Badge>,
  [CheckStatus.REQUESTED]: (
    <Badge className="border-blue bg-blue/10 text-blue">KYB: In progress</Badge>
  ),
  [CheckStatus.RESULT_READY]: (
    <Badge className="border-blue bg-blue/10 text-blue">KYB: In progress</Badge>
  ),
  [CheckStatus.UNKNOWN]: <Badge>KYB: Unknown</Badge>,
};

const kycCheckStatusMap = {
  [CheckStatus.FAIL]: <Badge className="border-pink bg-pink/10 text-pink">KYC: Fail</Badge>,
  [CheckStatus.PASS]: <Badge className="border-green bg-green/10 text-green">KYC: Pass</Badge>,
  [CheckStatus.REQUESTED]: (
    <Badge className="border-blue bg-blue/10 text-blue">KYC: In progress</Badge>
  ),
  [CheckStatus.RESULT_READY]: (
    <Badge className="border-blue bg-blue/10 text-blue">KYC: In progress</Badge>
  ),
  [CheckStatus.UNKNOWN]: <Badge>KYC: Unknown</Badge>,
};

type Props = {
  data: User;
};

const handleSetKybKycPass: React.MouseEventHandler<HTMLButtonElement> = (event) => {
  const userId = event.currentTarget.dataset.user;

  if (!userId) {
    toast.error('User is not exist');
    return;
  }

  saveUserId(userId);

  openDialog(DialogType.KYB_KYC_PASS, {
    showCloseIcon: true,
  });
};

export const UserCard: React.FC<Props> = ({ data }) => {
  const {
    id,
    firstName,
    lastName,
    email,
    isEmailVerified,
    mobile,
    isMobileVerified,
    homeAddress,
    businessName,
    businessAddress,
    businessSetupCompleted,
    trustName,
    trustAddress,
    providingBuildingService,
    account,
    backgroundCheck,
    status,
  } = data;
  const searchParams = useSearchParams();
  const q = searchParams.get('q') ?? '';
  const { mutate: initImpersonationMutate, isPending: initImpersonationIsPending } =
    useInitImpersonation();
  const { mutate: toggleActiveStatusMutate, isPending: toggleActiveStatusIsPending } =
    useToggleActiveStatus();
  const fullName = [firstName, lastName].filter(Boolean).join(' ');
  const hasBusiness = Boolean(businessName || trustName);

  const comments = backgroundCheck?.kycStatus?.comments;
  const failReason = backgroundCheck?.kycStatus?.failReason;
  const showTooltip = Boolean(comments || failReason);
  const creationDate = dayjs(data.createdAt).tz('Australia/Sydney');
  const canImpersonate = status === EntityStatus.ACTIVE;

  const handleImpersonate: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const username = event.currentTarget.dataset.user;

    if (!username) {
      toast.error('Username is not exist');
      return;
    }

    saveUsername(username);
    initImpersonationMutate({ username });
  };

  const handleStatusToggle: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const userId = event.currentTarget.dataset.user;

    if (!userId) {
      toast.error('User is not exist');
      return;
    }

    saveUserId(userId);
    toggleActiveStatusMutate({ userId });
  };

  return (
    <Panel
      title={
        <div className="flex items-end justify-between gap-x-2">
          <h3 className="text-xl font-semibold">
            <Highlight match={q}>{fullName}</Highlight>
          </h3>
          <div className="select-none text-grey-dark">
            {providingBuildingService ? 'Building services provider' : 'Property owner'}
          </div>
        </div>
      }
    >
      <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
        <div className="grid grid-cols-[130px_1fr] gap-x-2">
          <div className="font-medium">User ID</div>
          <div>{id}</div>
        </div>
        <div className="grid grid-cols-[130px_1fr] gap-x-2">
          <div className="font-medium">Email address</div>
          <div className="flex items-center gap-x-1">
            <div>
              <Highlight match={q}>{email}</Highlight>
            </div>
            {isEmailVerified ? null : (
              <Badge className="border-pink bg-pink/10 text-pink">Unverified</Badge>
            )}
          </div>
        </div>
        <div className="grid grid-cols-[130px_1fr] gap-x-2">
          <div className="font-medium">Mobile number</div>
          <div className="flex items-center gap-x-1">
            <div>
              <Highlight match={q}>{mobile}</Highlight>
            </div>
            {isMobileVerified ? null : (
              <Badge className="border-pink bg-pink/10 text-pink">Unverified</Badge>
            )}
          </div>
        </div>
        {hasBusiness ? (
          <>
            {businessName ? (
              <div className="grid grid-cols-[130px_1fr] gap-x-2">
                <div className="font-medium">Business name</div>
                <div>
                  <Highlight match={q}>{businessName}</Highlight>
                </div>
              </div>
            ) : null}
            {businessAddress ? (
              <div className="grid grid-cols-[130px_1fr] gap-x-2">
                <div className="font-medium">Business address</div>
                <div>
                  <Highlight match={q}>{businessAddress}</Highlight>
                </div>
              </div>
            ) : null}
            {trustName ? (
              <div className="grid grid-cols-[130px_1fr] gap-x-2">
                <div className="font-medium">Trust name</div>
                <div>
                  <Highlight match={q}>{trustName}</Highlight>
                </div>
              </div>
            ) : null}
            {trustAddress ? (
              <div className="grid grid-cols-[130px_1fr] gap-x-2">
                <div className="font-medium">Trust address</div>
                <div>
                  <Highlight match={q}>{trustAddress}</Highlight>
                </div>
              </div>
            ) : null}
            <div className="grid grid-cols-[130px_1fr] gap-x-2">
              <div className="font-medium">Business status</div>
              <div>
                {businessSetupCompleted ? (
                  <Badge className="border-green bg-green/10 text-green">Setup completed</Badge>
                ) : (
                  <Badge className="border-pink bg-pink/10 text-pink">Setup incomplete</Badge>
                )}
              </div>
            </div>
          </>
        ) : homeAddress ? (
          <div className="grid grid-cols-[130px_1fr] gap-x-2">
            <div className="font-medium">Home address</div>
            <div>
              <Highlight match={q}>{homeAddress}</Highlight>
            </div>
          </div>
        ) : null}
        <div className="grid grid-cols-[130px_1fr] gap-x-2">
          <div className="font-medium">User status</div>
          <div className="flex flex-wrap items-center gap-1">
            {status === EntityStatus.ACTIVE ? (
              <Badge className="border-green bg-green/10 text-green">Active</Badge>
            ) : (
              <Badge className="border-pink bg-pink/10 text-pink">Inactive</Badge>
            )}
          </div>
        </div>
        <div className="grid grid-cols-[130px_1fr] gap-x-2">
          <div className="font-medium">Account status</div>
          <div className="flex flex-wrap items-center gap-1">
            {backgroundCheck?.kycStatus?.checkStatus ? (
              kycCheckStatusMap[backgroundCheck?.kycStatus.checkStatus]
            ) : (
              <Badge>KYC: Not started</Badge>
            )}
            {hasBusiness ? (
              backgroundCheck?.kybStatus?.checkStatus ? (
                kybCheckStatusMap[backgroundCheck.kybStatus.checkStatus]
              ) : (
                <Badge>KYB: Not started</Badge>
              )
            ) : null}
            {account ? (
              account.provisioned ? (
                <Badge className="border-green bg-green/10 text-green">Provisioned: Yes</Badge>
              ) : (
                <Badge className="border-pink bg-pink/10 text-pink">Provisioned: No</Badge>
              )
            ) : (
              <Badge>No account</Badge>
            )}
            <Tooltip hidden={!showTooltip} title={comments || failReason}>
              <InfoIcon className="size-4 cursor-pointer text-grey-tertiary transition-colors hover:text-grey-dark" />
            </Tooltip>
          </div>
        </div>
        <div className="grid grid-cols-[130px_1fr] gap-x-2">
          <div className="font-medium">Creation date</div>
          <div>
            {creationDate.format('DD MMM YYYY HH:mm:ss')} ({creationDate.fromNow()})
          </div>
        </div>
        <div className="col-span-1 flex flex-wrap gap-2 pt-4 sm:col-span-2">
          <Tooltip disabled={canImpersonate} title="You can not impersonate an off platform user.">
            <Button
              full={false}
              size="sm"
              variant="outline"
              data-user={data.email}
              loading={initImpersonationIsPending}
              onClick={handleImpersonate}
              disabled={!canImpersonate}
            >
              {initImpersonationIsPending ? 'Impersonating...' : 'Impersonate'}
            </Button>
          </Tooltip>
          <Button
            full={false}
            size="sm"
            variant="outline"
            loading={toggleActiveStatusIsPending}
            data-user={data.id}
            onClick={handleStatusToggle}
          >
            {status == EntityStatus.ACTIVE ? 'Deactivate' : 'Activate'}
          </Button>
          {account?.id ? (
            <Tooltip title="You have a transaction account apply in progress.">
              <Button
                disabled
                full={false}
                size="sm"
                variant="outline"
                data-user={data.id}
                hidden={account?.provisioned}
                onClick={handleSetKybKycPass}
              >
                Set KYB/KYC Pass
              </Button>
            </Tooltip>
          ) : (
            <Button
              full={false}
              size="sm"
              variant="outline"
              data-user={data.id}
              onClick={handleSetKybKycPass}
            >
              Set KYB/KYC Pass
            </Button>
          )}
        </div>
      </div>
    </Panel>
  );
};
